import "@hotwired/turbo-rails"
import * as coreui from '@coreui/coreui'

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import WebAuthnController from "stimulus-web-authn"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Register all controllers from /app/frontend/controllers/*
const controllers = import.meta.glob('/javascript/controllers/**/*_controller.js', { eager: true })
registerControllers(application, controllers)
application.register("web-authn", WebAuthnController)
